import React from 'react';
// import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row } from 'react-grid-system';
// import Title from 'components/title';
import Component from '@reach/component-component';
import { Dialog } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import BgImage from 'assets/images/work/alice/alice.png';
import ModalThumb from 'assets/images/work/alice/alice2.jpg';
import ModalImage01 from 'assets/images/work/alice/alice_beat1.jpg';
import ModalImage02 from 'assets/images/work/alice/alice_beat2.jpg';
import ModalImage03 from 'assets/images/work/alice/alice_beat3.jpg';
import ModalImage04 from 'assets/images/work/alice/alice_beat4.jpg';

export const Wrapper = styled.div`
  margin-top: -4rem;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const SectionWrapper = styled.section`
  .section__img-inner {
    background-image: url(${BgImage});
    background-position: 50% 0%;
  }
  ${MEDIA.TABLET`
    display: block;
    .section__img-inner {
      background-image: url(${BgImage});
      background-position: 0% 0%;
    }
  `};
`;

const ImageModal = () => (
  <Component initialState={{ showDialog: false }}>
    {({ state, setState }) => (
      <div>
        <button onClick={() => setState({ showDialog: true })}>
          <img src={ModalThumb} alt="Alice in Wonderland Thumbnail" />
        </button>

        <Dialog
          isOpen={state.showDialog}
          onDismiss={() => setState({ showDialog: false })}
        >
          <button
            className="close-button"
            onClick={() => setState({ showDialog: false })}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </button>
          <p style={{ width: '100%' }}>
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage01}
              alt="Alice in Wonderland Website"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage02}
              alt="Doorway to Website"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage03}
              alt="Doorway to City"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage04}
              alt="Doorway to Insane"
            />
          </p>
        </Dialog>
      </div>
    )}
  </Component>
);

const Section = () => (
  <SectionWrapper className="section section--current">
    <div className="section__content">
      <h1 className="section__title">Alice</h1>
      <p className="section__description">
        <span className="section__description-inner">
          The challenge presented to TIC by EA Games was to create 4 entirely
          different worlds, each domain of the Alice: Madness Returns game, in a
          single website. In a precisely coordinated fashion we designed and
          integrated each skin release with the EA team as the game release
          dates drew near, increasing the excitement and hype surrounding the
          game. In addition TIC conceptualized a visually brilliant and engaging
          rich media ad campaign to blanket the internet with this amazing and
          unique property.
        </span>
      </p>
    </div>
    <div className="section__img">
      <div className="section__img-inner" />
    </div>
    <div className="section__more">
      <div className="section__more-inner section__more-inner--bg1">
        <span className="section__more-text">Want to know more?</span>
        <a href="/work" className="section__more-link">
          <span className="section__more-linktext">Explore all projects</span>
        </a>
      </div>
    </div>
    <ul className="section__facts">
      <li
        className="section__facts-item section__facts-item--clickable"
        data-gallery="gallery1"
      >
        <div className="section__facts-img">
          <ImageModal />
        </div>
        <h2 className="section__facts-title">EA Games</h2>
      </li>
      <li className="section__facts-item">
        <h3 className="section__facts-title">Design</h3>
        <span className="section__facts-detail">
          Storytelling, Graphic, Web Development
        </span>
      </li>
    </ul>
  </SectionWrapper>
);

const Alice = () => (
  <Layout>
    <Container fluid>
      <Row>
        <Wrapper className="sections">
          {/* <Fact /> */}
          <Section />
        </Wrapper>
      </Row>
    </Container>
  </Layout>
);

export default Alice;
